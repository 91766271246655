import React, { useEffect, useRef } from 'react'
import { CountUp as Count } from 'countup.js'

const CountUp = React.memo(({ className, style, endVal, ...props }) => {
  const formattingFn = useRef()
  const element = useRef()
  const countUp = useRef()

  if (!formattingFn.current)
    formattingFn.current = new Count(null, null, props).formattingFn

  useEffect(() => {
    if (countUp.current)
      countUp.current.update(endVal)
    else {
      countUp.current = new Count(element.current, endVal, props)
      countUp.current.start()
    }
  }, [ endVal ])

  return (
    <span ref={element} className={className} style={style}>
      {formattingFn.current(props.startVal, props)}
    </span>
  )
}, (prevProps, nextProps) => prevProps.endVal == nextProps.endVal)

export default CountUp
