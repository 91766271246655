const normalizeOptions = options => options?.map(option => {
  const optionType = typeof option
  switch (optionType) {
  case 'object':
    return Array.isArray(option)
    ? { label: option[0], value: option[1] }
    : option
  case 'string': case 'number': return { label: option, value: option }
  default: throw new Error(`Option type not implemented: ${optionType}`)
  }
})

export default normalizeOptions
