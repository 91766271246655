import React from 'react'

import CountUpWrapper from 'src/components/CountUpWrapper'
import Progress       from './Progress'

import formatNumber from 'src/utils/formatNumber'

const Goal = ({ total_raised_with_matching, goal, donor_count, completed }) =>
  <div>
    {
      donor_count == 0 && !completed &&
      <h2 className="h3 text-center">
        ✨ Be the first to donate ✨
      </h2>
    }
    <div>
      {
        goal > 0
      ? <div>
          <h2 className="h3 text-center d-flex align-items-center justify-content-center column-gap-1">
            {donor_count > 0 && '✨'}
            <span>
              <CountUpWrapper amount={total_raised_with_matching} prefix="$" />
              <small className="text-body-secondary text-nowrap fw-normal fs-4">
                {` of $${formatNumber(goal).split('.')[0]} goal`}
              </small>
            </span>
            {donor_count > 0 && '✨'}
          </h2>
          <Progress raised={total_raised_with_matching} goal={goal} />
          <p className="mb-0">
            raised by <b>{donor_count}</b> {donor_count == 1 ? 'person' : 'people'}
          </p>
        </div>
      : donor_count > 0
      ? <h2 className={`text-center text-nowrap ${total_raised_with_matching >= 1000000 ? 'h3' : ''}`}>
          {'✨ '}
          <CountUpWrapper amount={total_raised_with_matching} prefix="$" />
          {' raised ✨'}
          <small className="d-block text-body-secondary fw-normal fs-4">
            by <b>{donor_count}</b> {donor_count == 1 ? 'person' : 'people'}
          </small>
        </h2>
      : null
      }
    </div>
  </div>

export default Goal
