import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Donations from './Donations'
import useInfiniteOrders from './useInfiniteOrders'

const All = ({ organizer, logged_in, more_orders_available, ...props }) => {
  const { orders, loading, refs } = useInfiniteOrders({
    orders: props.orders,
    more_orders_available,
  })

  return (
    <>
      <div ref={refs.top} className="position-relative"></div>
      <Donations orders={orders} organizer={organizer} logged_in={logged_in} />

      <div
        ref={refs.bottom}
        className="text-center mt-n4"
        style={{ opacity: loading ? 1 : 0, transition: 'opacity 0.1s' }}
      >
        <FontAwesomeIcon icon="spinner" pulse />
      </div>
    </>
  )
}

export default All
