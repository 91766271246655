import safeJSON from 'src/utils/safeJSON'

const showModal = id => $(document.getElementById(id)).modal('show')

const handleMessage = e => {
  if (e.origin != location.origin) return

  const data = safeJSON(e.data)
  switch (data.message) {
    case 'showModal':
      return showModal(data.data)
  }
}

addEventListener('message', handleMessage)
