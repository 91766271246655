import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

const script = document.currentScript
const rootId = script.dataset.rootId
export const root = document.getElementById(rootId)

const showMessage = status => {
  root.parentNode.querySelectorAll('.loading-message')
  .forEach(message => message.style.display = 'none')

  if (status) {
    const selector = `[data-loading-status="${status}"]`
    const message = root.parentNode.querySelector(selector)
    if (message) message.style.display = 'block'
  }
}

const errorListener = e => {
  if (!root.children.length) {
    showMessage('crashed')
    root.parentNode.querySelectorAll('.restore-on-crash').forEach(element =>
      element.classList.remove('restore-on-crash')
    )
  }
}

const mountReactApp = (App, transformProps) => {
  root.addEventListener('MountReactApp', e => {
    try {
      const callback = () => {
        observer.disconnect()
        showMessage(null)
      }
      const observer = new MutationObserver(callback)
      observer.observe(root, { childList: true })

      addEventListener('error', errorListener)
      const props = transformProps?.(e.detail) || e.detail
      createRoot(root).render(<StrictMode><App {...props} /></StrictMode>)
    } catch (e) {
      showMessage('failed')
      console.error(e)
    }
  })
}

export default mountReactApp
