import csrfHeaders from 'src/utils/csrfHeaders'

const toSnakeCase = s => s.replace(/([A-Z])/g, (m, l) => `_${l.toLowerCase()}`)
const toKebabCase = s => s.replace(/([A-Z])/g, (m, l) => `-${l.toLowerCase()}`)

const convert = obj => Object.keys(obj).reduce((newObj, key) => {
  newObj[toSnakeCase(key)] = obj[key]
  return newObj
}, {})

// handle clicking the reaction buttons
const handleClick = e => {
  const button = e.target.closest('.reaction-button')
  if (!button) return

  const reacted = button.classList.contains('active')
  const count = parseInt(button.dataset.reactionCount) + (reacted ? -1 : 1)

  const updateButton = button => {
    button.classList.toggle('active')
    button.dataset.reactionCount = count
    button.querySelector('.reaction-counter').textContent = count || ''
  }

  // update all reaction buttons for the same activity
  Array.from(document.querySelectorAll(
    [ 'activityType', 'activityId', 'reactionType' ].reduce((selector, key) =>
      selector.concat(`[data-${toKebabCase(key)}="${button.dataset[key]}"]`)
    , '')
  )).forEach(updateButton)

  // server-side call to create/delete the Reaction
  fetch('/reactions', {
    method: reacted ? 'DELETE' : 'POST',
    headers: csrfHeaders(),
    body: JSON.stringify({ reaction: convert(button.dataset) }),
  });
}

const handleSignInClick = e => {
  const button = e.target.closest('.reaction-signin')
  if (!button) return

  if (!parseInt(getComputedStyle(button).opacity))
    e.preventDefault()
}

document.addEventListener('click', handleClick)
document.addEventListener('click', handleSignInClick)
