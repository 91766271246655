const moneyFormat = (format, falbackCurrencySymbol) => {
  const pattern = /^([^\{]*)\{\{\s*(amount[a-z_]*)\s*\}\}([^\}]*)$/

  const [ , prefix, amountFormat, suffix ] =
    pattern.exec(format) || [ , falbackCurrencySymbol, 'amount', '' ]

  const { separator, decimal } = (() => {
    switch (amountFormat) {
      case 'amount_with_comma_separator':
      case 'amount_no_decimals_with_comma_separator':
        return { separator: '.', decimal: ',' }
      case 'amount_with_apostrophe_separator':
        return { separator: "'", decimal: '.' }
      default:
        return { separator: ',', decimal: '.' }
    }
  })()

  return { prefix, suffix, separator, decimal }
}

export default moneyFormat
