import React from 'react'

const SpriteIcon = ({ icon, file, className, ...props }) =>
  <svg
    {...props}
    className={[ 'sprite-icon', className ].filter(Boolean).join(' ')}
  >
    <use href={[ file, icon ].join('#')} />
  </svg>

export default SpriteIcon
