import React from 'react'

import cloudinaryUrl from 'src/utils/cloudinaryUrl'

const ProfilePicture = ({ source, size }) => {
  if (!source?.trim()) return null

  const width = /^\d+(\.\d+)?rem$/.test(size)
  ? parseFloat(size) * 16 * 2 - 4
  : (() => {
      switch (size) {
      case 'sm': return 29
      case 'lg': return 46
      case 'xl': throw new Error('XL profile pictures are not supported yet')
      case 'xxl': return 78
      default: return 36
      }
    })() * 2
  const src = cloudinaryUrl(source, { width, height: width, crop: 'pad' })
  return (
    <img
      src={src}
      className="bg-white position-absolute w-100 h-100 rounded-circle"
      style={{ top: 0, left: 0 }}
    />
  )
}

export default ProfilePicture
