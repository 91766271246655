export const capitalize = str => {
  const firstCP = str.codePointAt(0)
  const index = firstCP > 0xFFFF ? 2 : 1

  return String.fromCodePoint(firstCP).toUpperCase() + str.slice(index)
}

export const parameterize = str =>
  str.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '')

export const camelize = str =>
  str.replace(/[\W_]+([a-z])/ig, (m, c) => c.toUpperCase())
