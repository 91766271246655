import React from 'react'

import SpriteIcon     from 'src/components/SpriteIcon'
import CountUpWrapper from 'src/components/CountUpWrapper'
import Progress       from './Progress'

import formatNumber from 'src/utils/formatNumber'
import moneyFormat  from 'src/utils/moneyFormat'

const Impact = ({ icon, iconFile, money_format, currency_symbol, amount, goal, description, impact_type }) => {
  const showGoal = (() => {
    switch (impact_type) {
      case 'dollar': return true
      case 'either': return !!currency_symbol
      default: return false
    }
  })()

  return (
    <div>
      <h2 className="h3 d-flex align-items-center column-gap-3">
        <SpriteIcon icon={icon} file={iconFile} />
        <span>
          <CountUpWrapper
            {...moneyFormat(money_format, currency_symbol)}
            amount={amount}
          />
          {' '}
          <small className="text-body-secondary text-nowrap fw-normal fs-4">
            { showGoal
              ? goal > 0
              ? `of $${formatNumber(goal).split('.')[0]} goal`
              : 'in donations'
              : description
            }
          </small>
        </span>
      </h2>
      { showGoal && goal > 0 ?
        <Progress raised={amount} goal={goal} />
        : null
      }
    </div>
  )
}

export default Impact
