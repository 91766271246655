import React, { forwardRef } from 'react'
import normalizeOptions from 'src/utils/normalizeOptions'

const addonMarkupInner = addon =>
  typeof addon == 'string'
  ? <span className="input-group-text">{addon}</span>
  : addon

const addonMarkup = (type, addon) => addon &&
  <div className={`input-group-addon input-group-${type}`}>
    {addonMarkupInner(addon)}
  </div>

const InputField = ({
  label, prepend, append, hint, horizontal, bs5,
  id, errors, error = errors?.[id]?.[0],
  required, requiredLabel = required,
  type = 'text',
  columns = [ 'col-sm-3', 'col-sm-9' ],
  wrapperClass = horizontal ? 'form-group mb-3 row' : 'form-group mb-3',
  labelClass = horizontal ? `control-label form-label col-form-label ${columns[0]}` : 'form-label',
  groupClass = 'input-group',
  className = (() => {
    switch (type) {
    case 'select': return 'custom-select form-select'
    case 'color': return 'form-control form-control-color'
    case 'range': return 'form-range'
    default: return 'form-control'
    }
  })(),
  children, options,
  ...props
}, ref) => {

  const labelId = label && `${id}-label`
  const labelMarkup = label &&
    <label id={labelId} htmlFor={id} className={labelClass}>
      {requiredLabel ? <>{label} <abbr title="required">*</abbr></> : label}
    </label>

  const errorId = error && `${id}-error`
  const errorMarkup = error &&
    <div id={errorId} className="help-block invalid-feedback" role="alert">
      <span className="sr-only visually-hidden">{label || id}</span>
      {' '}{error}
    </div>

  const hintId = hint && `${id}-hint`
  const hintMarkup = hint &&
    <small id={hintId} className="help-block d-block form-text text-muted">{hint}</small>

  const inputMarkup = children || (() => {
    const inputProps = {
      ...props, id, ref, required,
      className: `${className} ${error ? 'is-invalid' : ''}`,
      'aria-labelledby': labelId,
      'aria-describedby': [ errorId, hintId ].filter(Boolean).join(' '),
      'aria-invalid': error ? 'true' : null,
      'aria-required': required ? 'true' : null,
    }

    if ([ 'function', 'object' ].indexOf(typeof type) > -1) {
      const Input = type
      return <Input {...inputProps} options={options} />
    }

    switch (type) {
      case 'select':
        return (
          <select {...inputProps}>
            {normalizeOptions(options)?.map(({ label, ...option }, i) =>
              <option
                key={option.value?.toString() || 'null_option'}
                {...option}
              >{label}</option>
            )}
          </select>
        )
      case 'textarea':
        return <textarea {...inputProps}></textarea>
      default:
        return <input type={type} {...inputProps} />
    }
  })()

  const inputGroupMarkup = (prepend || append) &&
    <div className={`${groupClass} ${error ? 'is-invalid' : ''}`}>
      {bs5 ? addonMarkupInner(prepend) : addonMarkup('prepend', prepend)}
      {inputMarkup}
      {bs5 ? addonMarkupInner(append) : addonMarkup('append', append)}
    </div>

  const horizontalMarkup = horizontal &&
    <div className={columns[1]}>
      {inputGroupMarkup || inputMarkup}
      {errorMarkup}
      {hintMarkup}
    </div>

  return (
    <div className={`${wrapperClass} row-${id} ${error ? 'has-error' : ''}`}>
      {labelMarkup}
      {horizontalMarkup || inputGroupMarkup || inputMarkup}
      {horizontal ? null : errorMarkup}
      {horizontal ? null : hintMarkup}
    </div>
  )
}

export default forwardRef(InputField)
