import React, { useState, useEffect } from 'react'

const Progress = ({ raised, goal }) => {
  const [ progress, setProgress ] = useState(0)

  useEffect(() => {
    setProgress(Math.min(100, raised / goal * 100))
  }, [ raised, goal ])

  return (
    <div
      className="progress mb-1 rounded-pill"
      style={{ height: '0.5rem' }}
    >
      <div
        className="progress-bar"
        style={{ width: `${progress}%` }}
        role="progressbar"
        aria-valuenow={progress}
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  )
}

export default Progress
