import React, { useState } from 'react'
import { Flipper, Flipped, spring } from 'react-flip-toolkit'

import Donation from './Donation'

const Donations = ({ orders, organizer, logged_in }) => {
  const [ visibleCommentField, setVisibleCommentField ] = useState(null)

  const toggleVisibleCommentField = commentFieldId =>
    setVisibleCommentField(visibleCommentField =>
      commentFieldId == visibleCommentField ? null : commentFieldId)

  return orders.length
  ? <Flipper
      flipKey={orders.map(o => o.id).join()}
      element="ul"
      className="list-unstyled mb-0"
    >
      {
        orders.map(order =>
          <Flipped
            key={order.id}
            flipId={order.id.toString()}
            onAppear={(el, index) => {
              spring({
                config: { overshootClamping: true },
                values: { opacity: [ 0, 1 ] },
                onUpdate: ({ opacity }) => el.style.opacity = opacity,
              })
            }}
            onExit={(el, index, removeElement) => {
              spring({
                config: { overshootClamping: true },
                values: { opacity: [ 1, 0 ] },
                onUpdate: ({ opacity }) => el.style.opacity = opacity,
                onComplete: removeElement,
              })
            }}
          >
            <li className="border-top mpx-edge mx-sm-0 px-sm-4">
              <Donation
                order={order}
                organizer={organizer}
                logged_in={logged_in}
                visibleCommentField={visibleCommentField}
                toggleVisibleCommentField={toggleVisibleCommentField}
              />
            </li>
          </Flipped>
        )
      }
    </Flipper>
  : <div className="border-top mpx-edge mx-sm-0 px-sm-4 py-4">
      There are currently no donations.
    </div>
}

export default Donations
