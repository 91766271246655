import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

import Impact from './goal/Impact'
import Goal   from './goal/Goal'
import Top    from './donations/Top'
import All    from './donations/All'

import safeJSON from 'src/utils/safeJSON'
import mountIfExists from 'src/utils/mountIfExists'

const App = props => {
  const [ flp, setFlp ] = useState(props.fundraiserLandingPage)
  const [ impactWidget, setImpactWidget ] = useState(props.impactWidget)
  const [ orders, setOrders ] = useState(props.orders)
  const [ topOrders, setTopOrders ] = useState(props.top_orders)

  const fetchRecentDonations = () =>
    fetch(location.pathname, { headers: { Accept: 'application/json'} })
    .then(response => response.json()).then(data => {
      setFlp(data.fundraiser_landing_page)
      setImpactWidget(data.impact_widget)
      setOrders(data.orders)
      setTopOrders(data.top_orders)
    })

  useEffect(() => {
    const interval = setInterval(fetchRecentDonations, 30000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const handleMessage = e => {
      if (e.origin != location.origin) return

      const data = safeJSON(e.data)
      switch (data.message) {
        case 'DonateCompleted':
          return fetchRecentDonations()
      }
    }

    addEventListener('message', handleMessage)
    return () => removeEventListener('message', handleMessage)
  }, [])

  return (
    <div>
      {
        Array.prototype.map.call(
          document.querySelectorAll('.GoalRoot'),
          (goalRoot, i) => {
            const placeholder = goalRoot.querySelector('.placeholder')
            placeholder && goalRoot.removeChild(placeholder)

            return createPortal(
              impactWidget
              ? <Impact key={i}
                  {...impactWidget}
                  iconFile={props.iconFile}
                  goal={flp.goal} // replace with goal on impact widget later
                />
              : <Goal key={i} {...flp} />,
              goalRoot
            )
          }
        )
      }
      {
        mountIfExists('RecentDonationsRoot', () =>
          <All
            orders={orders}
            more_orders_available={props.more_orders_available}
            organizer={props.organizer}
            logged_in={props.logged_in}
          />
        )
      }
      {
        mountIfExists('TopDonationsRoot', () =>
          <Top
            topOrders={topOrders}
            organizer={props.organizer}
            logged_in={props.logged_in}
          />
        )
      }
    </div>
  )
}

export default App
