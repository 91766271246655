export const csrfToken = () =>
  document.querySelector('meta[name="csrf-token"]').getAttribute('content')

const csrfHeaders = headers => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-CSRF-Token': csrfToken(),
  ...headers,
})

export default csrfHeaders
