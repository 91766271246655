import React, { useState } from 'react'

import InputField from 'src/components/InputField'
import csrfHeaders from 'src/utils/csrfHeaders'

const maxLength = 150

const CommentField = ({
  order, comment, toggleVisibleCommentField,
  onCommentCreated, onCommentUpdated, onCommentDeleted,
}) => {
  const [ value, setValue ] = useState(comment?.body || '')
  const [ error, setError ] = useState(null)

  const newComment = !comment?.uuid

  const handleInput = e => {
    setValue(e.target.value)
    setError(null)
  }

  const createComment = () => {
    fetch('/comments', {
      method: 'POST',
      headers: csrfHeaders(),
      body: JSON.stringify({ comment: { order_uuid: order.uuid, body: value } }),
    }).then(response => response.json().then(json => {
      if (response.ok) {
        onCommentCreated({ ...json, body: value })
        setValue('')
        setError(null)
        toggleVisibleCommentField(null)
      } else {
        setError(json.body?.[0])
      }
    }))
  }

  const updateComment = () => {
    fetch(`/comments/${comment.uuid}`, {
      method: 'PATCH',
      headers: csrfHeaders(),
      body: JSON.stringify({ comment: { body: value } }),
    }).then(response => {
      if (response.ok) {
        onCommentUpdated({ body: value })
        toggleVisibleCommentField(null)
      } else {
        response.json().then(json => setError(json.body?.[0]))
      }
    })
  }

  const deleteComment = () => {
    if (!confirm('Are you sure you want to delete this comment?')) return

    fetch(`/comments/${comment.uuid}`, {
      method: 'DELETE',
      headers: csrfHeaders(),
    }).then(response => {
      if (response.ok) {
        onCommentDeleted()
        toggleVisibleCommentField(null)
      } else {
        setError('Error deleting comment. Please try again.')
      }
    })
  }

  const handleCancel = () => toggleVisibleCommentField(null)

  const handleSubmit = e => {
    e.preventDefault()

    if (!value.length)
      return setError('can’t be blank')
    if (value.length > maxLength)
      return setError(`can’t be longer than ${maxLength} characters`)


    if (newComment)
      createComment()
    else
      updateComment()
  }

  return (
    <form onSubmit={handleSubmit}>
      <InputField
        id="comment_body"
        type="textarea"
        value={value}
        rows="3"
        onInput={handleInput}
        autoFocus
        error={error}
      />
      <div className="d-flex flex-wrap align-items-center column-gap-3 row-gap-2">
        <small className="text-body-secondary">
          <span className={value.length > maxLength ? 'text-danger' : ''}>
            {value.length}
          </span>/{maxLength} characters
        </small>
        <div className="d-flex column-gap-1 ms-auto">
          {
            !newComment &&
            <button
              type="button"
              className="btn btn-link link-danger"
              onClick={deleteComment}
            >
              Delete
            </button>
          }
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button className="btn btn-primary">
            {newComment ? 'Add Comment' : 'Update'}
          </button>
        </div>
      </div>
    </form>
  )
}

export default CommentField
