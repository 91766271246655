import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TimeAgo from 'react-timeago'
import defaultFormatter from 'react-timeago/lib/defaultFormatter'

import ProfilePicture from 'src/components/ProfilePicture'
import CommentField from './CommentField'
import csrfHeaders from 'src/utils/csrfHeaders'

const Comment = ({ comment, organizer, visibleCommentField, toggleVisibleCommentField }) => {
  const [ content, setContent ] = useState(comment.body)
  const [ deleted, setDeleted ] = useState(false)

  const handleCommentUpdated = ({ body }) => setContent(body)
  const handleCommentDeleted = () => setDeleted(true)

  // 'flagging' is just deleting the comment as of now
  const flagComment = () => {
    if (!confirm([
      'Are you sure you want to remove this donor’s comment?',
      'This action cannot be undone.',
    ].join('\n'))) return

    fetch(`/comments/${comment.uuid}`, {
      method: 'DELETE',
      headers: csrfHeaders(),
    }).then(response => {
      if (response.ok) {
        handleCommentDeleted()
        toggleVisibleCommentField(null)
      } else {
        // This should almost never happen IRL
        alert('Error deleting comment. Please try again.')
      }
    })
  }

  return (
    !deleted &&
    <>
      {
        comment.from_donor
      ? <div className="d-flex align-items-center column-gap-2">
          <div className="p-3 shadow-primary-1 rounded-4 me-5" style={{
            backgroundColor: 'rgba(var(--bs-primary-rgb), 0.05)'
          }}>
            {content}
          </div>
          {
            organizer.isCurrentUser &&
            <div className="ms-n5">
              <button
                className="btn btn-sm btn-light align-self-center rounded-circle shadow-0"
                style={{ paddingLeft: 0, paddingRight: 0, width: '1.9375rem' }}
                onClick={flagComment}
                title="Remove comment"
              >
                <FontAwesomeIcon icon="trash" className="text-primary pe-none" />
              </button>
            </div>
          }
        </div>
      : <div className="d-grid column-gap-2" style={{ gridTemplateColumns: '1fr auto' }}>
          <div className="d-flex align-items-center justify-content-end column-gap-2">
            {
              organizer.isCurrentUser &&
              <div className="me-n5">
                <button
                  className={[
                    "btn btn-sm btn-light align-self-center rounded-circle shadow-0",
                    visibleCommentField == comment.uuid && 'active',
                  ].filter(Boolean).join(' ')}
                  style={{ paddingLeft: 0, paddingRight: 0, width: '1.9375rem' }}
                  onClick={() => toggleVisibleCommentField(comment.uuid)}
                  title="Edit comment"
                >
                  <FontAwesomeIcon icon="pencil" className="text-primary pe-none" />
                </button>
              </div>
            }
            <div className="p-3 shadow-primary-1 rounded-4 ms-5">
              {content}
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div>{/* Just in case so it doesn't squish */}
              <div className="initials initials-sm position-relative" aria-hidden="true">
                {organizer.initials}
                <ProfilePicture source={organizer.profilePicture} size="sm" />
              </div>
            </div>
          </div>
          <div className="text-end text-body-secondary pt-1 px-3">
            <small>
              {organizer.name} (Organizer), <TimeAgo
                date={comment.created_at}
                formatter={(value, unit, suffix) =>
                  unit == 'second'
                  ? 'less than a minute ago'
                  : defaultFormatter(value, unit, suffix)
                }
              />
            </small>
          </div>
        </div>
      }
      {
        visibleCommentField == comment.uuid &&
        <CommentField
          comment={comment}
          toggleVisibleCommentField={toggleVisibleCommentField}
          onCommentUpdated={handleCommentUpdated}
          onCommentDeleted={handleCommentDeleted}
        />
      }
    </>
  )
}

export default Comment
